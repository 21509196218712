/* styles.css */

.file-field {
  display: block;
  min-width: 300px;
  max-width: 700px;
  margin: 30px auto auto 50px;
}

.border-field {
  height: 97vh;
  box-sizing: border-box;
}

.button-base {
  padding-left: 25px;
  padding-right: 25px;
}

.table-base {
  max-width: 1570px;
  overflow-x: auto;
}
